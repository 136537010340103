<template>
  <el-alert type="success" :closable="false" class="explanation">
    <h6>更新出席狀態說明</h6>
    請在輸入框內輸入 P（Present）、L （Late）、E （Excused）和 A（Absent），四種狀態。
  </el-alert>
</template>

<script>
export default {};
</script>

<style scoped>
.explanation {
  margin: 20px 0px;
}

::v-deep h6 {
  color: #42a16a;
}
</style>
