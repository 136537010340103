<template>
  <el-table :data="studentsAttendances">
    <el-table-column fixed width="160">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: scope.row.student.id }
          }"
        >
          <b>{{ scope.row.student.name }}</b>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      width="54px"
      :key="lessonDate.date"
      v-for="lessonDate in lessonDates"
      align="center"
    >
      <template slot="header">
        <div v-if="disableLinkToTeachingLog" class="lessonDate">
          <router-link
            :to="
              `/private/private-lessons/${classId}/log/${lessonDate.teaching_log_id}`
            "
          >
            <span>{{ lessonDate.date }}</span>
          </router-link>
          <router-link
            :to="
              `/private/private-lessons/${classId}/log/${lessonDate.teaching_log_id}`
            "
          >
            <span class="lessonDay">{{ lessonDate.day }}</span>
          </router-link>
        </div>
        <div v-else class="lessonDate">
          <div>
            <span>{{ lessonDate.date }}</span>
          </div>
          <div>
            <span class="lessonDay">{{ lessonDate.day }}</span>
          </div>
        </div>
      </template>
      <template slot-scope="scope">
        <AttendanceStatusTextLight
          v-if="displayAttendancesStatusByText"
          :attendanceStatus="
            scope.row.attendances[lessonDate.date]
              ? scope.row.attendances[lessonDate.date].status
              : 0
          "
        />
        <AttendanceStatusLight
          v-else
          :attendanceStatus="
            scope.row.attendances[lessonDate.date]
              ? scope.row.attendances[lessonDate.date].status
              : 0
          "
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import AttendanceStatusTextLight from "@/components/privateLesson/AttendanceStatusTextLight";
import AttendanceStatusLight from "@/components/privateLesson/AttendanceStatusLight";

export default {
  components: {
    AttendanceStatusLight,
    AttendanceStatusTextLight
  },
  props: {
    classId: {
      type: String,
      default: ""
    },
    lessonDates: {
      type: Array,
      default: () => []
    },
    studentsAttendances: {
      type: Array,
      default: () => []
    },
    displayAttendancesStatusByText: {
      type: Boolean,
      default: true
    },
    disableLinkToTeachingLog: {
      type: Boolean,
      default: true
    },
    displayStudentUserIds: {
      type: Array,
      default: null
    }
  },
  methods: {
    async showStudents(studentsAttendances) {
      if (this.displayStudentUserIds) {
        studentsAttendances.filter(({ student_user_id }) =>
          this.displayStudentUserIds.includes(student_user_id)
        );
      } else {
        return studentsAttendances;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lessonDate {
  color: rgba(66, 161, 106, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  .lessonDay {
    font-size: 14px;
  }
}

::v-deep .el-table__row {
  .is-center {
    padding: 8px 0px;

    .cell {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
</style>
