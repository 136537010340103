var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{attrs:{"data":_vm.studentsAttendances}},[_c('el-table-column',{attrs:{"fixed":"","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'ProfileForAdmin',
          params: { id: scope.row.student.id }
        }}},[_c('b',[_vm._v(_vm._s(scope.row.student.name))])])]}}])}),_vm._l((_vm.lessonDates),function(lessonDate){return _c('el-table-column',{key:lessonDate.date,attrs:{"width":"54px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.displayAttendancesStatusByText)?_c('AttendanceStatusTextLight',{attrs:{"attendanceStatus":scope.row.attendances[lessonDate.date]
            ? scope.row.attendances[lessonDate.date].status
            : 0}}):_c('AttendanceStatusLight',{attrs:{"attendanceStatus":scope.row.attendances[lessonDate.date]
            ? scope.row.attendances[lessonDate.date].status
            : 0}})]}}],null,true)},[_c('template',{slot:"header"},[(_vm.disableLinkToTeachingLog)?_c('div',{staticClass:"lessonDate"},[_c('router-link',{attrs:{"to":`/private/private-lessons/${_vm.classId}/log/${lessonDate.teaching_log_id}`}},[_c('span',[_vm._v(_vm._s(lessonDate.date))])]),_c('router-link',{attrs:{"to":`/private/private-lessons/${_vm.classId}/log/${lessonDate.teaching_log_id}`}},[_c('span',{staticClass:"lessonDay"},[_vm._v(_vm._s(lessonDate.day))])])],1):_c('div',{staticClass:"lessonDate"},[_c('div',[_c('span',[_vm._v(_vm._s(lessonDate.date))])]),_c('div',[_c('span',{staticClass:"lessonDay"},[_vm._v(_vm._s(lessonDate.day))])])])])],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }