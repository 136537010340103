<template>
  <div>
    <el-table :data="studentsAttendances">
      <el-table-column fixed width="160">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: scope.row.student.id }
            }"
          >
            <b>{{ scope.row.student.name }}</b>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        width="54px"
        :key="lessonDate.date"
        v-for="(lessonDate, columnIndex) in lessonDates"
        align="center"
      >
        <template slot="header">
          <div class="lessonDate">
            <router-link
              :to="
                `/private/private-lessons/${classId}/editLog/${lessonDate.lesson_log_id}`
              "
            >
              <span>{{ lessonDate.date }}</span>
            </router-link>
            <router-link
              :to="
                `/private/private-lessons/${classId}/editLog/${lessonDate.lesson_log_id}`
              "
            >
              <span class="lessonDay">{{ lessonDate.day }}</span>
            </router-link>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input
            :class="
              `attendance ${getAttendance(
                scope.row.attendances[lessonDate.date]
              )}`
            "
            type="text"
            :data-inputPosition="`${columnIndex}_${scope.$index}`"
            v-if="studentHasLessonLog(scope.row.attendances[lessonDate.date])"
            :value="
              EnumShortAttendanceStatuses[
                scope.row.attendances[lessonDate.date].status
              ]
            "
            @input="
              newStatus =>
                checkInputStatusIsCorrectAndUpdate(
                  scope.row.student.id,
                  scope.row.attendances[lessonDate.date].lesson_log_id,
                  newStatus
                )
            "
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { EnumShortAttendanceStatuses, EnumAttendanceStatuses } from "@/enums";
export default {
  props: {
    classId: {
      type: String,
      default: ""
    },
    lessonDates: {
      type: Array,
      default: () => []
    },
    studentsAttendances: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    EnumShortAttendanceStatuses() {
      return EnumShortAttendanceStatuses;
    },
    EnumAttendanceStatuses() {
      return EnumAttendanceStatuses;
    }
  },
  data() {
    return {
      currentInputPosition: []
    };
  },
  created() {
    window.addEventListener("keydown", this.moveInputToNextPosition);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.moveInputToNextPosition);
  },
  methods: {
    getAttendance({ status: attendance }) {
      switch (attendance) {
        case this.EnumAttendanceStatuses.Present:
          return "present";
        case this.EnumAttendanceStatuses.Late:
          return "late";
        case this.EnumAttendanceStatuses.Absent:
          return "absent";
        case this.EnumAttendanceStatuses.Excused:
          return "excused";
        default:
          return "";
      }
    },
    focusCurrentInput() {
      const [inputXpos, inputYpos] = this.currentInputPosition;
      const targetInput = document.querySelector(
        `[data-inputPosition='${inputXpos}_${inputYpos}']`
      );
      setTimeout(() => {
        targetInput.select();
      });
    },
    moveInputToNextPosition(e) {
      const [UP, DOWN, LEFT, RIGHT] = [
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight"
      ];
      if (![UP, DOWN, LEFT, RIGHT].includes(e.code)) return;

      const currentInputPosition = document.activeElement.dataset.inputposition;
      if (currentInputPosition === undefined) {
        this.currentInputPosition = [0, 0];
      } else {
        const [currentInputXpos, currentInputYpos] = currentInputPosition.split("_");
        let [newInputXpos, newInputYpos] = [
          Number(currentInputXpos),
          Number(currentInputYpos)
        ];
        const MAX_X_POSITION = this.lessonDates.length - 1;
        const MAX_Y_POSITION = this.studentsAttendances.length - 1;
        switch (e.code) {
          case UP:
            newInputYpos -= 1;
            newInputYpos = newInputYpos < 0 ? MAX_Y_POSITION : newInputYpos;
            break;
          case DOWN:
            newInputYpos += 1;
            newInputYpos = newInputYpos > MAX_Y_POSITION ? 0 : newInputYpos;
            break;
          case LEFT:
            newInputXpos -= 1;
            newInputXpos = newInputXpos < 0 ? MAX_X_POSITION : newInputXpos;
            break;
          case RIGHT:
            newInputXpos += 1;
            newInputXpos = newInputXpos > MAX_X_POSITION ? 0 : newInputXpos;
            break;
          default:
        }

        this.currentInputPosition = [newInputXpos, newInputYpos];
      }
      this.focusCurrentInput();
    },
    studentHasLessonLog(attendance) {
      return attendance !== null;
    },
    checkInputStatusIsCorrectAndUpdate(studentUserId, lessonLogId, newStatus) {
      try {
        const newStatusUpperCase = newStatus.toUpperCase();
        if (this.EnumShortAttendanceStatuses[newStatusUpperCase]) {
          this.$emit("setStudentLessonLogAttendances", {
            studentUserId,
            lessonLogId,
            attendanceStatus: this.EnumShortAttendanceStatuses[
              newStatusUpperCase
            ]
          });
          this.$emit("updateStudentAttendance", {
            lessonLogId,
            attendanceStatus: this.EnumShortAttendanceStatuses[
              newStatusUpperCase
            ]
          });
        } else {
          throw "unexpectation key";
        }
      } catch (e) {
        this.$message.error("請輸入正確的出席狀態（P、L、E 或 A）！");
      }
      this.focusCurrentInput();
    }
  }
};
</script>

<style lang="scss" scoped>
.lessonDate {
  color: rgba(66, 161, 106, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  .lessonDay {
    font-size: 14px;
  }
}

::v-deep .el-table__row {
  .is-center {
    padding: 8px 0px;

    .cell {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

::v-deep .attendance {
  input {
    background: transparent;
    font-weight: bolder;
    text-align: center;
  }
}

.present {
  background: #c4f2d7;
}

.excused {
  background: #B5DEFF;
}
.late {
  background: #f0c9c5;
}
.absent {
  background: #e49d9c;
}
</style>
