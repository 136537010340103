<template>
  <div :class="lightClassName">
    <span :content="statusShortText">
      {{ statusShortText }}
    </span>
  </div>
</template>

<script>
import { EnumAttendanceStatuses } from "@/enums";

export default {
  props: {
    attendanceStatus: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      isOnline: 1
    };
  },
  computed: {
    lightClassName() {
      switch (this.attendanceStatus) {
        case EnumAttendanceStatuses.Present:
          return "markPresent";
        case EnumAttendanceStatuses.Late:
          return "markLate";
        case EnumAttendanceStatuses.Absent:
          return "markAbsent";
        case EnumAttendanceStatuses.Excused:
          return "markExcused";
      }
      return "";
    },
    statusShortText() {
      switch (this.attendanceStatus) {
        case EnumAttendanceStatuses.Present:
          return "P";
        case EnumAttendanceStatuses.Late:
          return "L";
        case EnumAttendanceStatuses.Absent:
          return "A";
        case EnumAttendanceStatuses.Excused:
          return "E";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  font-weight: bolder;

  span {
    position: relative;
    border-radius: 20px;
    color: #333333;
  }
}

.markPresent {
  background: #c4f2d7;
}

.markExcused {
  background: #B5DEFF;
}
.markLate {
  background: #f0c9c5;
}
.markAbsent {
  background: #e49d9c;
}
</style>
