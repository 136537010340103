<template>
  <div>
    <div class="content">
      <el-table :data="schedules" style="width: 100%">
        <el-table-column label="Date" align="center" width="500px">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-col :span="10">
                <el-date-picker
                  style="width: 130px"
                  size="mini"
                  v-model="scope.row.started_at"
                  type="date"
                  value-format="yyyy-MM-dd"
                  @change="
                    value => {
                      setSchedule(scope.row.no, { started_at: value });
                    }
                  "
                />
              </el-col>
              <el-col :span="6">
                <el-time-select
                  style="width: 100px"
                  size="mini"
                  v-model="scope.row.time"
                  :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:45'
                  }"
                  placeholder="time"
                  @change="
                    value => {
                      setSchedule(scope.row.no, { time: value });
                    }
                  "
                />
              </el-col>
              <el-col :span="12">
                <TimezoneSelector
                  :value="scope.row.timezone"
                  @change="
                    value => {
                      setSchedule(scope.row.no, { timezone: value });
                    }
                  "
                  size="mini"
                />
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="Lesson" align="center">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-col :span="4">
                <el-input
                  size="mini"
                  :value="scope.row.duration_hours"
                  @input="
                    value => {
                      setSchedule(scope.row.no, { duration_hours: value });
                    }
                  "
                />
              </el-col>
              <el-col :span="4">
                hr and
              </el-col>
              <el-col :span="4">
                <MinutesInput
                  size="mini"
                  width="100"
                  :value="scope.row.duration_minutes"
                  :change="value => setSchedule(scope.row.no, { duration_minutes: value })"
                />
              </el-col>
              <el-col :span="4">
                min(s)
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="Delete" align="center">
          <template slot-scope="scope">
            <span
              class="warning-icon"
              @click="
                () => {
                  removeSchedules(scope.row.no);
                }
              "
            >
              <i class="el-icon-delete" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" @click="addSchedules">
        Add
      </el-button>
    </div>
    <hr />
    <span slot="footer" class="dialog-footer footer">
      <el-button @click="$emit('closeLessonScheduledForm')">
        Cancel
      </el-button>
      <el-button type="primary" @click="submitScheduled">
        Save
      </el-button>
    </span>
  </div>
</template>

<script>
import moment from "moment";
import { TimezoneSelector } from "@/components/selector";
import MinutesInput from "@/components/privateLesson/MinutesInput";
import formMixin from "@/mixins/form";

class Schedule {
  constructor() {
    this.no = Math.random();
    this.started_at = moment(new Date()).format("YYYY-MM-DD");
    this.time = "10:00";
    this.timezone = null;
    this.duration_hours = 2;
    this.duration_minutes = 0;
  }
}

export default {
  mixins: [formMixin],
  components: {
    TimezoneSelector,
    MinutesInput
  },
  props: {
    classId: {
      type: Number,
      default: null
    },
    teacherUserId: {
      type: Number,
      default: null
    },
    salary: {
      type: Number,
      default: 0
    },
    salaryCurrency: {
      type: String,
      default: "TWD"
    },
    studentClassTickets: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      schedules: []
    };
  },
  async created() {
    this.addSchedules();
  },
  methods: {
    addSchedules() {
      const schedule = new Schedule();
      schedule.timezone = this.$store.getters["user/timezone"];
      this.schedules = [...this.schedules, schedule];
    },
    removeSchedules(no) {
      this.schedules = this.schedules.filter(schedule => schedule.no !== no);
    },
    setSchedule(no, value) {
      const workSchedules = [...this.schedules];
      const scheduleIndex = this.schedules.findIndex(
        schedule => schedule.no === no
      );
      workSchedules[scheduleIndex] = {
        ...this.schedules[scheduleIndex],
        ...value
      };

      this.schedules = workSchedules;
    },
    submitScheduled() {
      const schedules = this.schedules.map(schedule => ({
        class_id: this.classId,
        teacher_user_id: this.teacherUserId,
        salary: this.salary,
        salary_currency: this.salaryCurrency,
        started_at: `${schedule.started_at} ${schedule.time}`,
        timezone: schedule.timezone,
        duration_hours: schedule.duration_hours,
        duration_minutes: schedule.duration_minutes,
        has_mailed: 0,
        mail_to_admin: 0,
        mail_to_teacher: 0,
        mail_to_student: 0,
        mail_to_parents: 0,
        students: this.studentClassTickets.map(studentClassTicket => ({
          student_class_ticket_id: studentClassTicket.id,
          fee: studentClassTicket.fee,
          fee_currency: studentClassTicket.fee_currency,
          attendance_status: 1,
          has_vocab_test: 0
        }))
      }));

      this.$emit("createSchedules", schedules);
    }
  }
};
</script>

<style scoped>
.content {
  margin: 20px 0px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
