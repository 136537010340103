<template>
  <div>
    <ClassTypeDialog
      :defaultIsOnline="classTypeIsOnline"
      :visible="classTypeDialogVisible"
      @submit="
        newIsOnline => {
          classTypeIsOnline = newIsOnline;
          closeClassTypeDialog();
          dispatchOpenAddLessonLogDialog();
        }
      "
      @toggleDialogVisible="visible => (classTypeDialogVisible = visible)"
    />
    <el-dialog
      v-if="addLessonLogDialogVisible"
      title="新增上課日"
      :visible="addLessonLogDialogVisible"
      width="1200px"
    >
      <div v-if="hasMultipleTeachers" class="choiceTeacher">
        <span>
          請選擇上課老師：
        </span>
        <TeacherSelector
          :teachers="teachersUserProfile"
          :value="teachingTeacher.id"
          @change="teacher => (teachingTeacher = teacher)"
        />
      </div>
      <LessonScheduledForm
        :classId="Number(theClass.session_class.id)"
        :teacherUserId="choseTeacher.id"
        :salary="salary"
        :salaryCurrency="salaryCurrency"
        :studentClassTickets="students"
        @createSchedules="createLessonLogs"
        @closeLessonScheduledForm="closeAddLessonLogDialog"
      />
    </el-dialog>
    <el-tooltip
      content="新增上課日"
      placement="top"
      popper-class="tooltipColor"
    >
      <el-button type="primary" size="mini" @click="openAddLessonLogDialog">
        <i class="fas fa-plus" />
        Add
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
import LessonScheduledForm from "@/views/lessonLog/LessonScheduledForm";
import ClassTypeDialog from "@/components/privateLesson/ClassTypeDialog";
import { TeacherSelector } from "@/components/selector";
import lessonLogApi from "@/apis/lessonLog";

export default {
  components: {
    LessonScheduledForm,
    ClassTypeDialog,
    TeacherSelector
  },
  computed: {
    theClass() {
      return this.$store.getters["studentAttendance/theClass"];
    },
    teachers() {
      return this.$store.getters["studentAttendance/theClassTeachers"];
    },
    teachersUserProfile() {
      return this.$store.getters["studentAttendance/theClassTeachers"].map(
        ({ teacher_user }) => teacher_user
      );
    },
    hasMultipleTeachers() {
      return 1 < this.$store.getters["studentAttendance/theClassTeachers"].length;
    },
    students() {
      return this.$store.getters["studentAttendance/theClassStudents"];
    },
    choseTeacher() {
      const teacher = this.teachers.find(
        ({ teacher_user_id }) => teacher_user_id === this.teachingTeacher.id
      );

      if (this.theClass.class_is_private) {
        return teacher.teacher_user;
      } else {
        return teacher.teacher;
      }
    },
    salary() {
      const teacher = this.teachers.find(
        ({ teacher_user_id }) => teacher_user_id === this.teachingTeacher.id
      );

      if (this.theClass.class_is_private) {
        return this.classTypeIsOnline
          ? Number(teacher.online_salary)
          : Number(teacher.salary);
      } else {
        return 0;
      }
    },
    salaryCurrency() {
      const teacher = this.teachers.find(
        ({ teacher_user_id }) => teacher_user_id === this.teachingTeacher.id
      );

      if (this.theClass.class_is_private) {
        return this.classTypeIsOnline
          ? teacher.online_salary_currency
          : teacher.salary_currency;
      } else {
        return "TWD";
      }
    }
  },
  data() {
    return {
      classTypeDialogVisible: false,
      addLessonLogDialogVisible: false,
      teachingTeacher: null,
      classTypeIsOnline: null
    };
  },
  methods: {
    dispatchOpenAddLessonLogDialog() {
      this.addLessonLogDialogVisible = true;
    },
    closeAddLessonLogDialog() {
      this.addLessonLogDialogVisible = false;
    },
    closeClassTypeDialog() {
      this.classTypeDialogVisible = false;
    },
    openClassTypeDialog() {
      this.classTypeDialogVisible = true;
    },
    openAddLessonLogDialog() {
      if (this.theClass.class_is_private) {
        this.classTypeIsOnline = this.theClass.session_class.is_online;
        this.teachingTeacher = this.teachers[0].teacher_user;
        this.classTypeDialogVisible = true;
      } else {
        this.teachingTeacher = this.teachers[0];
        this.dispatchOpenAddLessonLogDialog();
      }
    },
    createLessonLogs(schedules) {
      let pomiseSchedules = [];
      if (this.theClass.class_is_private) {
        pomiseSchedules = schedules
          .map(schedule => ({ ...schedule, is_online: this.classTypeIsOnline }))
          .map(async schedule => await lessonLogApi.createLog(schedule));
      } else {
        pomiseSchedules = schedules
          .map(schedule => ({ ...schedule, is_online: this.classTypeIsOnline }))
          .map(async schedule => await lessonLogApi.createGroupLessonLog(schedule));
      }

      try {
        Promise.all(pomiseSchedules).then(async values => {
          this.closeAddLessonLogDialog();
          this.$emit("getClass", { id: this.theClass.session_class.id });
        });
        this.$message.success(
          this.$t("message.lesson_log_created_successfully")
        );
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
.choiceTeacher {
  display: flex;
}

.choiceTeacher > span {
  width: 200px;
}

::v-deep .el-dialog__body {
  padding-bottom: 30px;
}

.plusIcon {
  font-size: 26px;
}
</style>
